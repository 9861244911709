import React, { useEffect } from "react"
import { getProducts } from "../functions/queries/products"

const TestProductPage = ({ serverData }) => {

    useEffect(() => {
        console.log(serverData);
    }, [])

    return <>Das ist ein Test</>
    
}

export default TestProductPage

export async function getServerData(context) {

    var axios = require('axios');

    var config = {
        method: 'post',
        url: 'https://spectory.at/nimmervoll/wp-json/siegfried/v1/shop/product',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { slug: 'pflanzschiffchen-salim-rebe-geschwungen-dunkelbraun' }
    };

    let response = await axios(config);


    let relatedProducts = await getProducts(context, {perPage: 5, category: response.data.categories[0]?.slug});

    return {
        props: {
            product: response.data,
            relatedProducts: relatedProducts.products
        }
    }
}
